import React, { Component } from 'react';

class Explore extends Component{

	render(){
		const explore = this.props.explore;
		return(
			<section className='section highlight-section'>
				<header className='section-header row'>
					<div className='col'>
						<h2 className='section-title'>{explore.exploreTitle}</h2>
					</div>
				</header>
				<div className='section-content row explore-more' style={{display: 'flex', justifyContent: 'center'}}>
					<div className='col md-6 explore-container'>
						<a className='flex-container' href={explore.exploreTile[0].route}>
							<img src={explore.exploreTile[0].backgroundImage.url} alt={explore.exploreTile[0].backgroundImage.title} />
							<span className='explore-more-content'>
								<h3>{explore.exploreTile[0].title}</h3>
								<span className='explore-link btn-primary'>{explore.exploreTile[0].ctaText}</span>
							</span>
							<span className='blue-tint'></span>
						</a>
					</div>
					{/*<div className='col md-6 explore-container '>
						<a className='flex-container' href={explore.exploreTile[1].route} >
							<img src={explore.exploreTile[1].backgroundImage.url} alt={explore.exploreTile[1].backgroundImage.title} />
							<span className='explore-more-content'>
								<h3>{explore.exploreTile[1].title}</h3>
								<span className='explore-link btn-primary'>{explore.exploreTile[1].ctaText}</span>
							</span>
							<span className='blue-tint'></span>
						</a>
					</div>   */} 
				</div>
			</section>
		);

	}
}

export default Explore;