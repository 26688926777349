import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';

export default function homeWithData(WrappedComponent, options = {}) {
	const HOC = class extends Component {
		constructor(props) {
			super(props);
			this.wrapped = graphql(gql `query homePageQuery {
				newsItems {
						videoLink
			            newsTitle
			            route
			            date
			            description
			            department{
			            	name
			            	route
			            }
			              image {
							    title
							    description
							    url
							}
					}
					galleryPages(q:"fields.route=video-series") {
					  backgroundHeader
					  backgroundDescription
					  route
					  galleryGroups{
					  	name
					  	description
					  	galleryItems{
					  		name
					  		description
					  		releaseDate
					  		snapshotTime
					  		showDetails
					  		file{
			                  title
			                  description
			                  url
			                }
			            }
					  }
				    backgroundImage {
				      title
				      description
				      url
				    }
					}
			   globalSites{
			   	defaultImage {
						   title
						   description
						   url
						 }
					homePage {
						featuredTweet {
					        tweet
					        route
					        twitterHandle
					      }
						 backgroundImage {
						   title
						   description
						   url
						 }
						 socialMediaHeader
						 newsHeader
						 valueHeader
						 quickLinks {
						   route
						   text
	   		       fileLink{
                 title
                 description
                 url
               }
						 }
						 externalLinks {
						   route
						   text
						   image{
							url
							title
						   }
						 }
						 socialMedia {
						   handle
						   handleType
						 }
						 valueSection {
						 	valueIcon {
                             title
                             description
                             url
                           }
						   valueHeader
						   valueTitle
						   valueDescription
						 }
						 missionComponent {
						 	backgroundImage {
                            title
                            description
                            url
                          }
                          missionLogo {
                            title
                            description
                            url
                          }
                          missionHeader
                          missionText
                          goalsHeader
                          goals
						 }
						 valuesImage{
						 	url
						 }
						
					}
					feedback {
						 feedbackHeader
						 feedbackDescription
						 feedbackCtAtext
					   feedbackImage {
						 title
						 description
						 url
						}
					}
				  mainNavigation {
						 footerHeader
						 footerBody
						 copyrightHolder
						 primaryNavigationLinks{
							 route
							 text
						 }
						 footerLinks{
						   route
						   text
						 }
						 secondaryFooterLinks {
						   route
						   text
						 }
						 footerLogo {
						   title
						   description
						   url
						 }
				   }
				   cubsLogo{
		              title
		              url
		            }
			  }
		  }
	  `, { name: 'homePageQuery' })(WrappedComponent);
		}

		render() {
			const Wrapped = this.wrapped;
			return <Wrapped {...this.props}/>;

		}
	};

	return HOC;
}