import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import {withRouter } from 'react-router-dom';

import ogImg from '../../assets/img/og.jpg';

import Image from '../../components/shared/backgroundImage';

import Footer from '../../components/shared/Footer';
import NavBar from '../../components/shared/NavBar';
import Description from '../../components/shared/imageDescription';
import NewsAndInfo from '../../components/shared/newsAndInfo';
import QuickLinks from '../../components/shared/quicklinks';
import Files from '../../components/shared/filesAndDocuments.js';
import Explore from '../../components/shared/explore.js';

import ReactMarkdown from 'react-markdown';


class generic extends Component { 
	constructor(props) {
		super(props);
		this.onTransitionEnd.bind(this);
		this.state = {
			index: null,
			data: null,
			loaded: false,
			transitionEnded: false,
		};
	}

	onTransitionEnd = () => {
		this.setState({ transitionEnded: true }); 
	};
	componentDidUpdate(){
		let location = this.props.location.pathname;
		location = location.toLowerCase();
		let path;
		if (this.state.index == null){
			if (!this.data){
			}
			
			if (this.data){
				path = this.data.genericPageWithNews;
				
				let i;

				for (i = 0; i < path.length; i++){
					if (location === '/departments/' + path[i].route){
						this.setState({index: i});
						break;
					}
				}
			}
		}
	}
	render() {
		const heroClasses = classnames('hero', {
			animate: this.state.transitionEnded
		});
		//if this.props.genericPageQuery is null wait, if not null check state of homePageQuery until done loading
		if (!this.props.genericPageQuery){
			return <div className="u-hidden"> Waiting</div>;
		}

		else if ((this.props.genericPageQuery && this.props.genericPageQuery.loading)) { 
			return <div className="u-hidden">Loading</div>; 
		}

		else if (this.props.genericPageQuery && this.props.genericPageQuery.error) {
			return <div className="u-hidden">Error </div>;
		}

		//done loading
		else {
			this.data = this.props.genericPageQuery;
			if (!this.data) {
				window.location.href = '/404';
				return null;
			} 
			else{
				if (this.state.index != null){
					return(
						<div key="home" className={heroClasses}>   
							<p className='hidden'> department: {this.props.location.pathname}</p>
							<Helmet>
								<meta property="og:image" content={ogImg}/>
								<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
							</Helmet>
							<header id="site-header" className="container" role="banner">
								<a id="site-title" className="h1" href="/"> <img src={this.data.globalSites[0].cubsLogo.url} alt = "site-header"/></a>
								<Image introBackgrounds={this.data.genericPageWithNews[this.state.index]}/>
								<NavBar NavBar={this.data.globalSites[0].mainNavigation.primaryNavigationLinks}/>
								<div className="header-content">
									<div className="row2 padding-bottom">
										<Description description= {this.data.genericPageWithNews[this.state.index]}/>
									</div>
								</div>
							</header>
							<main className="container">
								{this.data.genericPageWithNews[this.state.index].pageDescription && 
									<section className= 'section'>
										<div className= 'row'>
											<ReactMarkdown className="markdown-body" children= {this.data.genericPageWithNews[this.state.index].pageDescription}/>
										</div>
									</section>
								}
								<section className= 'section' style={{borderTop: "none"}}>
									<div className= 'row'>
										<NewsAndInfo news={this} header={this.data.genericPageWithNews[this.state.index].newsAndInformationHeader ? this.data.genericPageWithNews[this.state.index].newsAndInformationHeader : 'NEWS & INFORMATION'}/>
										<QuickLinks links={this.data.genericPageWithNews[this.state.index]}/>
									</div>
									<div className='row'>
										<Files files={this.data.genericPageWithNews[this.state.index]}/>
									</div>
								</section>
								<Explore explore={this.data.exploreComponents[0]}/>
							</main>
							<Footer footer={this.data.globalSites[0].mainNavigation}/>
						</div>
					);
				}
				else{
					return <div className="u-hidden">Loading</div>; 
				}
			}
		}
	}

}

export default withRouter(generic);