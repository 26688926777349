import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';
import { withRouter } from 'react-router-dom';

export default function genericWithData(WrappedComponent, options = {}) {
	const HOC = class extends Component {
		constructor(props) {
			super(props);
			this.wrapped = graphql(gql `query genericPageQuery {
				  newsItems {
			            newsTitle
			            route
			            videoLink
			            date
			            description
			            department{
			            	name
			            	route
			            }
			              image {
							    title
							    description
							    url
							}
					}
					  genericPageWithNews {
					    route
					    backgroundHeader
					    backgroundDescription
					    pageDescription
					    quickLinksHeader
					    popularDocumentsHeader
					    newsAndInformationHeader
					    fileAndDocumentsHeader
					    backgroundImage {
					      title
					      description
					      url
					    }
					    filesAndDocuments {
					      department
					      team
					      title
					      fileUpload {
			                  title
			                  description
			                  url
		                	}
		                	uploadDate
					    }
					    quickLinks {
					      route
					      text
					    }
					    popularDocuments {
					      fileLink{
			                  title
			                  description
			                  url
			                }
					      text
					    }
					    executive {
					      name
					      title
					      picture {
					        title
					        description
					        url
					      }
					    }
					  }
					  feedbackComponents {
					    feedbackHeader
					    feedbackDescription
					    feedbackCtAtext
					    feedbackImage {
					      title
					      description
					      url
					    }
					  }

					  headerFooterComponents {
					    footerHeader
					    footerBody
					    copyrightHolder
					    primaryNavigationLinks{
					      route
					      text
					    }
					    footerLinks{
					      route
					      text
					    }
					    secondaryFooterLinks {
					      route
					      text
					    }
					    footerLogo {
					      title
					      description
					      url
					    }
					  }
					  exploreComponents {
					    isNotDisplayed
					    exploreTitle
					    exploreTile {
					    backgroundImage {
						        title
						        description
						        url
						      }
					      route
					      title
					      ctaText
					    }
					  }
					    globalSites{
					    	feedback {
						 feedbackHeader
						 feedbackDescription
						 feedbackCtAtext
					   feedbackImage {
						 title
						 description
						 url
						}
					}
				  mainNavigation {
						 footerHeader
						 footerBody
						 copyrightHolder
						 primaryNavigationLinks{
							 route
							 text
						 }
						 footerLinks{
						   route
						   text
						 }
						 secondaryFooterLinks {
						   route
						   text
						 }
						 footerLogo {
						   title
						   description
						   url
						 }
				   }
						    cubsLogo {
						      title
						      description
						      url
						    }
						  }
					}
	  `, { name: 'genericPageQuery' })(WrappedComponent);
		}

		render() {
			const Wrapped = this.wrapped;
			return <Wrapped {...this.props}/>;

		}
	};

	return withRouter(HOC);
}